html {
  background-color:rgb(201, 201, 195);
}

@font-face {
  font-family: "future";
  src: url("fonts/the-future-black.woff2") format('woff');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: future, 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: black;
  text-decoration: none;
}

main a, .post a {
  color: blue !important;
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  margin: 10px;
  z-index: 2;
}

.loading {
  margin: 16px;
  position: absolute;
  left: 35px;
  background-color: none;
}

.back-blur {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  backdrop-filter: blur(6px);
  height: 100vh;
  width:100vw;
  z-index: -1;
}

/* */

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 10px 0 10px 0;
  padding: 10px;
}

article {
  display: inline-block;
  margin: 10px;
  margin-bottom: 5vh;
  vertical-align: top;
  margin-top: 1vh;
}

/* article:nth-child(1) {
  margin-top: 6vh;
}

article:nth-child(2) {
  margin-top: 12vh;
}

article:nth-child(3) {
  margin-top: 1vh;
} */

.box {
  margin: 10px;
  padding: 10px;
  width: 22vw;
  color: black;
}

.merch-intro {
  position: absolute;
  margin: 10px;
  padding: 10px;
  width: calc(100vw - 14vw - 40px);
  max-height: calc(45vh - 20px);
  margin-top: -40vh;
  overflow-y: scroll;
  color: black;
}

.merch-container:first-of-type {
  display: block;
}

.merch-container:first-of-type p {
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 60vw; 
}

.merch-container:first-of-type img, .merch-container:first-of-type a {
  display: none;
}

.box img {
  width: calc(100% - 20px);
}

.box a {
  color: black;
}

/* */

.box-main {
  margin: 10px;
  padding: 10px;
  width: 40vw;
  color: black;
}

.box-main a {
  color: black;
}

.box-main img {
  width: calc(100% - 20px)
}

.box-main iframe, .box-main-vid iframe {
  width: calc(100% - 20px);
  margin-bottom: 2vh;
}

h1 {
  position: absolute;
  top: 12px;
  right: 78px;
  font-size: 1.25em;
}

/* single post */

.post {
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner-post {
  margin: 20px 0 10px 0;
  padding: 10px;
  background: white;
  width: 50vw;
}

.inner-post div {
  margin: 10px;
}

.post-body {
  margin-bottom: 2em !important;
}

.post-title {
  font-size: 2em;
}

.post-span {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.post a {
  font-weight: bold;
}

.post-image img {
  width: calc(22vw - 20px);
  display: block;
    margin-left: auto;
    margin-right: auto;
}

.checkout a {
  border: 1px solid black;
  display: inline-block;
  border-radius: 3px;
  padding: 10px;
}

.merch .checkout {
  margin-top: 2vh;
}

.merch .post-body {
  margin-bottom: 2vh;
}

/* list */

.list-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  font-weight:400;
}

.list .container {
  position: absolute;
  display: block;
  max-width: 100%;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  background-color: white;
  color: black;
  width: 50vw;
  padding-top: 50px;
  max-height: 100vh;
  overflow-y: scroll;
  cursor: default;
}

.list article {
  margin: 0;
  display: block;
  cursor: default;
}

.list .box {
  width: calc(50vw - 20px);
  font-size: 0.75rem;
  color: black;
  padding: 0;
  display: inline-block;
  margin-top: 0;
  height: auto;
  cursor: default;
}

.list .box a {
  width: 50%;
  display: inline-block !important;
  vertical-align: top;
  font-weight: 400;
  height: 60px;
  overflow-y:visible;
  padding-bottom: 1vw;
  cursor: pointer !important;
}

.list .box a h3 {
  cursor: pointer;
}

.list .box .box-body {
  width: calc(24% - 2vw);
  display: inline-block !important;
  vertical-align: middle;
  margin-right: 1vw;
  margin-left: 1vw;
  height: 60px;
  overflow-y: scroll;
  /* border-bottom: 1px solid black; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.box-collection div {
  max-height: 20vh;
  overflow-y: scroll;
  /* border-bottom: 1px solid black; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    margin-top: 1em;
}

.box-collection div:hover {
  cursor: s-resize;
}

.list h3 {
  display: inline-block;
  color: black;
}

.category {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* home */

/* .home ~ .header:nth-child() {
  display: none;
} */

/* about */

.about-main p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

/* video */

.video-container  {
  display: block;
}

.video-container a {
  display: inline-block !important;
  cursor: pointer !important;
  text-decoration: underline;
}

a.video-link {
  color: black;
  text-decoration: none;
}

.video-container a span h3 {
  color: black;
  text-decoration: none;
}

video {
  width: 100%;
}

.video-wrapper {
  width: 100%;
  max-width: 700px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}

.video-wrapper:hover .controls {
  transform: translateY(0%);
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 30px;
  padding: 14px;
  width: 100%;
  max-width: 500px;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
}

.actions button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.actions button i {
  background-color: none;
  color: black;
  font-size: 30px;
}

input[type="range"] {
  -webkit-appearance: none !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: 4px;
  width: 350px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 6px;
}

input[type="range"]::-moz-range-progress {
  background: black;
}

.velocity {
  appearance: none;
  background: none;
  color: black;
  outline: none;
  border: none;
  text-align: center;
  font-size: 16px;
}

.mute-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.mute-btn i {
  background-color: none;
  color: black;
  font-size: 20px;
}

/* / */

.video-page .box-main {
  width: 60vw;
  margin-bottom: 6em;
  margin-top: 2em;
}

.video-container {
  margin-top: 4em;
}

.box-main-vid {
  width: 60vw;
  margin-bottom: 6em;
  margin-top: 2em;
}


/* landing */


.landing-page iframe{
  z-index: 5;
}

section.landing-inner {
  z-index: 9;
}

button.btn-primary, .btn-primary {
  position: absolute;        
   bottom: 10vh;
   left: 50%;                    
   transform: translate(-50%, 0);
   font-size: 1.5rem;
}

.btn-primary a {
  color: black;
}

.landing-box {
  position: absolute; 
  top: 45vh;
  left: 50%;  
  width: 400px;                 
  transform: translate(-50%, 0);
  max-height: calc(100% - 75vh);
}

.landing-text {
  position: absolute;     
  width: 20vw;   
  top: 10vw;
   left: 50%;                    
   transform: translate(-50%, 0);
   max-height: calc(100% - 30vh);
   overflow-y: scroll;
   color: black;
   font-size: 1rem;
   font-weight: 400;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
   background-color: white;
   padding: 1vw;
}

.landing-page + .header {
  display: none !important;
  visibility: hidden !important;
}

.details {
  font-size: 0.9em;
}

button.submit {
  color: black;
}

input, textarea {
  background-color: transparent;
  border: 1px solid black;
  border-radius: 5px;
}

input {
  height: 20px;
  width: 26vw;
  margin-left: 2vw;
}

textarea {
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 100%;
}

select:focus, textarea select:focus, input select:focus {
  box-shadow: none;
  outline: none;
}

.subscribe {
  font-size: 1.5em;
}
  

/* responsive */

@media only screen and (max-width: 600px) {

   /* nav */

   .list .container {
    width: 100vw;
  }

  .list .box {
    width: calc(100vw - 20px);
    /* margin-bottom: 20px; */
  }

  .list .box .box-body {
    position: absolute;
    height: 20px;
  }

  .list .box .box-body:nth-of-type(2) {
    margin-top: 20px;
  }

  .list .box a {
    height: 50px;
  }

  .list-button button {
    right: 30px !important;
  }

  .list-button img{
    width: 28px !important;
  }

  .help-item {
    font-size: 1.8em !important;
  }

  .close-item {
    font-size: 2.5em !important;
    top: -9px !important;
  }

  button.back {
    left: 60px !important;
    margin-top: -3px;
    font-size: 2em !important;
  }

  /* pages */

  .inner-post {
    margin-top: 60px;
    width: 75vw;
  }

  .post-image img {
    width: calc(75vw - 20px);
  }

  .box-main {
    width: 75vw;
    /* color: black; */
  }

  .video-page {
    width: 75vw;
  }

  .video-page .box-main {
    width: 75vw;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .container {
    margin-top: 60px;
  }

  .box {
    width: 32vw;
  }

  .box .box-body {
    font-size: 0.8em;
  }

  .box h3 {
    font-size: 1em;
  }

  h1 {
    top: 18px;
    right: 89px;
  }

  article {
    margin-left: 0;
    margin-right: 0;
  }

  .loading {
    margin-top: 22px;
    left: 42px;
  }

  .list .box-body {
    float: right;
    width: calc(40% - 2vw) !important;
  }

  .post-body, .post-span {
    font-size: 0.9em;
  }

  .pod {
    background-color: black;
    color: black;
  }

  .pod .box-main p, .pod .box-main a {
    color: black;
  }

  .merch-container, .books-container {
    display: block;
    width: 80vw;
  }

  .merch-container .box, .books-container .box{
      width: 75vw;
  }

  .merch-container:first-of-type p {
    width: 80vw;
    font-size: 0.8em;
    margin-left: -20px;
    margin-top: -20px;
    margin-bottom: 0px;
  }

  .landing-box {
    width: 300px;                 
  }

}