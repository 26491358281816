* {
  margin: 0;
  padding: 0;
}

/* help */

button {
  border: none;
  text-decoration: none;
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  font-weight: bold;
}

.menu-button {
  text-align: right;
}

.menu-button button{
  top: -1px;
  line-height: 1.5em;
  vertical-align: bottom;
  font-size: 1.5em;
  right: 54px;
  margin-top: -2px;
}

.list-button button{
  top: 1px;
  right: 25px;
  font-size: 1em;
  line-height: 1.25em;
  vertical-align: bottom;
}

.list-button img{
  width: 20px;
  margin-top: 5px;
}

.close-item, .help-item {
  font-size: 1.4em;
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  display: inline-block;
  top: 0px;
}

.close-item a, .help-item a, .list-button {
  display: inline-block;
  margin: 0 5px 0 5px;
}

.close-item {
  position: fixed;
  left: 10px;
  margin: 10px;
}

.help-item {
  right: 0px;
}

.help-me {
  position: absolute;
  top: 0px;
  right: 0px;
}

.close-item a.active {
  display: none;
}

.close-item a:hover {
  cursor: alias;
}

.help-item a:hover {
  cursor: help;
}

.menu-button button:hover{
  cursor: context-menu;
}

.list-button button:hover {
  cursor: s-resize;
}

button.back {
  cursor: pointer;
  font-weight: bold;
  position: fixed;
  top: 14px;
  font-size: 1.25em;
  left: 50px;
  z-index: 5;
}

.back a:hover {
  cursor: w-resize;
}

/* nav */

.header {
  position: fixed;
  right: 10px;
  margin: 10px;
  z-index: 5;
}

header {
  margin-right: 5px;
}

nav {
  margin-top: 35px
}

a.active {
  color: grey
}

.home-background { 
  position: fixed; 
  top: 0; 
  left: 0;
  margin: 0;
  border: none;
  width: 100vw;
  height: 100vh; 
  z-index: -1;
}

.home {
  font-weight: bold;
  font-size: 1.25em;
}

.nav-item {
  margin: 3px 0 3px 0;
}

.landing-page + .header {
  display: none !important;
  visibility: hidden !important;
}